<template>
  <div class="login-form">
    <div class="d--middle mar-v--1" style="display: none">
      <h3>
        <span class="collapse--hidden">{{ $store.state.config.name }}</span>
      </h3>
    </div>
    <el-form
      :size="$vars.sizes.form"
      :label-position="$vars.forms.label_position"
      :labelWidth="$vars.forms.label_width"
      ref="form"
      :model="instance"
      :rules="rules"
      class="form-login"
      :name="$options.name"
    >
      <el-form-item :label="$tf('common.login,,1')" prop="login">
        <el-input name="login" v-model="instance.login" :placeholder="$tf('login,,1')"></el-input>
      </el-form-item>

      <el-form-item :label="$tf('common.password')" prop="password">
        <el-input
          name="password"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="$tf('password')"
          v-model="instance.password"
          @focus="addEnterListener"
          @blur="removeEnterListener"
        >
          <img slot="suffix" :src="eyeFile" class="eye-icon" @click.stop="toggleShowPassword()" />
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="default" class="width--full" @click="submit" name="submit-btn" size="middle">{{ $tf('log_in_with_password') }}</el-button>
        <div class="text-center" style="margin-top: 2rem" v-if="$store.state.config.plugins.cproauth">
          <form-login-crypto-pro @submit="dispatchSubmitEvent" />
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import { symbolAsciiRule } from '@/apps/common/validator';
import closedEye from '@/assets/icons/closed-eye.png';
import openEye from '@/assets/icons/open-eye.png';
import FormLoginCryptoPro from './form-login-crypto-pro';

let empty = {
  login: '',
  password: ''
};

export default {
  name: 'FormLoginBasic',
  props: ['open'],
  components: { FormLoginCryptoPro },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    addEnterListener(e) {
      e.target.addEventListener('keyup', this.submitOnEnter);
    },
    removeEnterListener(e) {
      e.target.removeEventListener('keyup', this.submitOnEnter);
    },
    submitOnEnter(e) {
      if (e.keyCode === 13) this.submit();
    },
    reset() {
      this.$refs.form.resetFields();
      this.instance = _.cloneDeep(empty);
    },
    submit() {
      this.$refs.form.validate((valid) => valid && this.dispatchSubmitEvent({ ...this.instance }));
    },
    dispatchSubmitEvent(payload) {
      this.$emit('submit', payload);
    },
    validatorRequiredField(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please input the password again'));
      } else {
        callback();
      }
    }
  },
  data: function () {
    return {
      certificates: [],
      instance: _.cloneDeep(empty),
      showPassword: false,
      baseRules: {
        login: [{ type: 'string', message: 'error.required.login', trigger: 'change', validator: this.validatorRequiredField }, symbolAsciiRule],
        password: [{ type: 'string', message: 'error.required.password', trigger: 'change', validator: this.validatorRequiredField }, symbolAsciiRule]
      }
    };
  },
  computed: {
    configured() {
      return this.$store.state.app.configured;
    },
    eyeFile() {
      return this.showPassword ? openEye : closedEye;
    },
    rules() {
      return this.$applyRuleMessages(this.baseRules);
    }
  }
};
</script>

<style lang="stylus">
.login-form {
  .eye-icon {
    cursor: pointer;
  }

  .el-input__suffix {
    top: 3px;
  }

  .el-form .el-form-item {
    margin-bottom: 2rem !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
