<template>
  <img :src="$store.getters.themeLogo" class="page-login-logo" />
</template>

<script>
export default { name: 'PageLoginLogo' };
</script>

<style lang="stylus">
.page-login-logo {
  width: 7.5rem;
  height: 7.0625rem;
  margin-bottom: 0.3125rem;
}
</style>
